import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DepartmentApi } from '../../../services/phpServices';
import moment from 'moment';
import TModal from '../../../common/components/TModal';

interface DepartmentModalProps {
  open: boolean;
  onClose: () => void;
  handleDepartmentSelect: (departmentId: number) => void;
}

export default function DepartmentModal ({ open, onClose, handleDepartmentSelect }: DepartmentModalProps): JSX.Element {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>(0);

  const { data: allDepartment, refetch: departmentRefetch } = useQuery(
    ['/api/department/getAllDepartment/{getDeleted}/{minTime}/{maxTime}'],
    async () => {
      const { data } = await new DepartmentApi().getAllDepartment(
        false,
        moment().startOf('month').unix().toString(),
        moment().endOf('month').unix().toString()
      );
      return [{ department_id: 0, department_name: 'All' }, ...data];
    },
    { enabled: false }
  );

  useEffect(() => {
    departmentRefetch();
  }, [departmentRefetch]);

  const handleSubmit = async () => {
    try {
      handleDepartmentSelect(selectedDepartmentId);
      localStorage.setItem('DEPARTMENTID', selectedDepartmentId.toString());
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TModal open={open} onClose={onClose} title="Select Department" size='small'>
      <Box sx={{ p: 4 }}>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel>Select Department</InputLabel>
          <Select
            label="Select Department"
            value={selectedDepartmentId}
            onChange={(event) => setSelectedDepartmentId(Number(event.target.value))}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  overflowY: 'auto',
                },
              },
            }}
          >
            {allDepartment?.map((department: any) => (
              <MenuItem key={department.department_id} value={department.department_id}>
                {department.department_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ py: 2, justifyContent: 'flex-end' }}
        >
          <Grid item>
            <Button onClick={() => { onClose() }} variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </TModal>
  );
}
