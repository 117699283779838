
import React from 'react'
import {
  Modal,
  Box,
  type ModalProps,
  Grid,
  Typography,
  IconButton,
  CircularProgress
} from '@mui/material'
const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  bgcolor: 'background.paper'
}
type Props = {
  title: string
  loading?: boolean
  size?: 'large' | 'medium' | 'small'
} & ModalProps
export default function TModal ({
  onClose,
  title,
  loading,
  children,
  size,
  ...props
}: Props): JSX.Element {
  const widths = {
    large: 960,
    medium: 720,
    small: 600
  }
  return (
    <Modal {...props} onClose={() => { onClose?.({}, 'escapeKeyDown') }}>
      <Box
        sx={{ ...style, width: widths[size ?? 'medium'] }}
        className={loading === true ? 'positionRelative' : ''}
      >
        <Grid
          container
          alignItems="center"
          sx={{ px: 4, py: 2, backgroundColor: 'primary.main' }}
        >
          <Grid item xs>
            <Typography
              variant="h6"
              sx={{ textTransform: 'uppercase', color: 'primary.contrastText' }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Box>{children}</Box>
        {loading === true && (
          <div className="modalLoadingOverlay">
            <CircularProgress />
          </div>
        )}
      </Box>
    </Modal>
  )
}