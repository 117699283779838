import * as React from 'react';
import { Box, FormControl, TextField } from '@mui/material';

export type MessageInputProps = {
  onSubmit: (message: string) => void;
};

export default function MessageInput (props: MessageInputProps) {
  const { onSubmit } = props;
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const handleClick = () => {
    if (textAreaValue.trim() !== '') {
      onSubmit(textAreaValue.trim());
      setTextAreaValue('');
    }
  };
  return (
    <Box sx={{ p: 3 }}>
      <FormControl fullWidth>
        <TextField
          multiline
          minRows={2}
          fullWidth
          maxRows={4}
          placeholder="Type something here…"
          aria-label="Message"
          onChange={(e) => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          onKeyDown={(event) => {
            if (event.key === 'Enter' ) {
              handleClick();
            }
          }}
        />
      </FormControl>
    </Box>
  );
}
