import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { initUser, processAPIURL, saveToken } from './services';
const App: React.FC = () => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.has('url') && searchParams.has('token')) {
      const url = searchParams.get('url')
      const next = searchParams.get('next')
      const token = searchParams.get('token')
      processAPIURL(undefined, decodeURIComponent(url ?? ''))
      if (token) {
        saveToken(decodeURIComponent(token))
        initUser().then(() => {
          window.location.href = `/${next ?? 'who-is-working'}`;
        })
      }
      else
        alert('You do not have permission to view this page!\nError code: TOKEN_EXPIRED')
    } else {
      alert('You do not have permission to view this page!\nError code: MISSING_PARAMS')
    }
  }, [searchParams])
  return <></>
}

export default App


