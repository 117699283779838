import { formatTime } from '../../../common/dateTime';
import { Colors } from '../../../theme';
import { FMessage } from '../hooks/type';
import { Box, Stack, Typography } from '@mui/material';

type ChatBubbleProps = {
  variant: 'sent' | 'received';
  message?: FMessage
};

export default function ChatBubble (props: ChatBubbleProps) {
  const { message, variant  } = props;
  const isSent = variant === 'sent';
  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography variant='h5'>
          {message?.user?.name}
        </Typography>
        <Typography variant='caption'>{formatTime(message?.createdAt ?? 0)}</Typography>
      </Stack>
      <Box
        sx={{ position: 'relative' }}
      >
        <Box
          color={isSent ? 'primary' : 'neutral'}
          sx={{
            p: 2.5,
            borderRadius: '12px',
            borderTopRightRadius: isSent ? 0 : 12,
            borderTopLeftRadius: isSent ? 12 : 0,
            bgcolor: isSent
              ? Colors.primary
              : Colors.gray100
          }}
        >
          <Typography

            sx={{
              color: isSent
                ? 'var(--joy-palette-common-white)'
                : 'var(--joy-palette-text-primary)',
            }}
          >
            {message?.text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
