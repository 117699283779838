import Avatar from "../../../common/components/Avatar";
import { WidgetDto } from "../../../services/netServices";

import frame from '../../../assets/images/Frame.png'
import frame2 from '../../../assets/images/Frame2.png'
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
interface WorkingModalProps {
  workingData: WidgetDto[]
}
export default function PresentBody ({
  workingData,
}: WorkingModalProps): JSX.Element {
  const [items, setItems] = useState(workingData);
  useEffect(() => {
    setItems(workingData);
  }, [workingData]);
  // Function to rotate the array
  const rotateArray = () => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      const removedItems = updatedItems.splice(0, 2);
      updatedItems.push(...removedItems);
      return updatedItems;
    });
  };

  // Rotate the array every 2 seconds
  useEffect(() => {
    const interval = setInterval(rotateArray, 3000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  return <table className="table hide-scrollbar">
    <thead>
      <tr>
        <th className='colEmployee'>Employee</th>
        <th className='colScheduled'>Scheduled</th>
        <th className='colStatus'>Status</th>
      </tr>
    </thead>
    <tbody className='body2'>
      {items.map((item: WidgetDto, index: number) => (
        <tr key={`${item.EmployeeId}-${index}`} className="item">
          <td className='colEmployee'>
            <div className='employeeInfo'>
              <Avatar image={item.Avatar ?? ''} color={item.Color ?? ''} name={item.EmployeeName ?? ''} />
              <div className='containerName'>
                <Typography fontWeight="500">
                  {item.EmployeeName ?? ''}
                </Typography>
                <Typography fontWeight="500" variant="body2">
                  {item.GroupName ?? ''}
                </Typography>
              </div>
            </div>
          </td>
          <td className='colScheduled'>
            <div className='containerStt'>
              {item.Scheduled !== null && item.Scheduled !== '' ? item.Scheduled : '-'}
              {(Number(item.SBreakTime) !== 0) ? <span>{`(${item.SBreakTime})`}</span> : null}
              {/* {item.TShirtType?.Id !== 1 ? <span className='abbreviation' style={{ backgroundColor: item.TShirtType?.Color ?? '' }}>
              {item.TShirtType?.Abbreviation}
            </span> : null} */}
            </div>
          </td>
          <td className='colStatus'>
            <div className='containerStt'>
              <img src={frame} alt="Clock icon" />
              {item.ClockedTime ?? '-'}
              {(Number(item.TBreakTime) !== 0) ? <span>{`(${item.TBreakTime})`}</span> : null}
              {/* {item.TShirtType?.Abbreviation !== null ? <span className='abbreviation' style={{ backgroundColor: item.TShirtType?.Color ?? '' }}>
              {item.TShirtType?.Abbreviation}
            </span> : null} */}
              {item.ClockedTime?.includes('-') && <img src={frame2} alt="Additional status icon" />}
              {(item.IsLate) && <span className="badge late">Late</span>}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
}