// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import {
  getDatabase,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
} from "firebase/database"
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { defaultLoadItems, FRoom, FUser, Settings } from "./type"
import { FIREBASE } from "../../../config"
import { UserApi } from "../../../services/phpServices"
const userApi = new UserApi()

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = FIREBASE
// Initialize Firebase
export const APP = initializeApp(firebaseConfig)
export const DATABASE = getDatabase(APP)

export const getRoomId = (id: number, users: FUser[]) => {
  if (!users || users.length === 0) {
    return
  }
  return users.length === 1
    ? [Number(id), Number(users[0]._id)].sort((a, b) => a - b).join("_")
    : uuidv4()
}

export const getBasePath = (companyId?: string | null) => {
  if (companyId) return `${"master"}/${companyId}`
  return undefined
}

export function useRoomChat(settings: Settings, user: FUser) {
  const [rooms, setRooms] = useState<FRoom[]>([])
  const [contacts, setContacts] = useState<FUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const basePath = getBasePath(settings?.companyId)
  const loadRooms = (callback?: () => void) => {
    if (!user._id && basePath) return
    const roomsRef = query(
      ref(DATABASE, `${getBasePath(settings?.companyId)}/users/${user._id}/rooms`),
      orderByChild("lastMessage/createdAt"),
      limitToLast(defaultLoadItems),
    )
    onValue(
      roomsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const roomData = snapshot.val()
          const roomKeys = Object.keys(roomData);
          const roomLs = roomKeys.map((key) => {
            return {
              ...roomData[key],
              _id: key,
            }
          })
          .sort((a, b) => {
            return b?.lastMessage?.createdAt - a?.lastMessage?.createdAt
          })
          setRooms(roomLs);
          
        }
       callback?.()
      },
      () => {
       callback?.()
      },
    )
  }
  const getContacts = () => {
    userApi.getAllEmployees().then((response) => {
      const data: FUser[] = []
      response.data.map((contact: any) => {
        if (Number(contact.user_id) !== Number(user._id)) {
          data[contact.user_id] = {
            _id: contact.user_id,
            name: contact.fullname,
            avatar: contact.avatar,
            group_name: contact.groups ? contact.groups[0]?.group_name : "",
            color: contact.color,
          }
        }
        return contact
      })
      setContacts(
        data.filter((contact) => {
          return contact !== undefined
        }),
      )
    })
  }
  useEffect(() => {
    setIsLoading(true)
    getContacts()
    loadRooms(() => setIsLoading(false))
  }, [])
  return {
    isLoading,
    rooms,
    contacts,
  }
}
