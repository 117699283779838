import * as React from 'react';
import AvatarWithStatus from './AvatarWithStatus';
import { ChatProps, MessageProps, UserProps } from '../types';
import { Box, ListItem, ListItemButton, ListItemButtonProps, Stack, Typography } from '@mui/material';
import { FRoom } from '../hooks/type';
import { formatDate } from '../../../common/dateTime';

type ChatListItemProps = ListItemButtonProps & {
  chat: FRoom,
  selectedChatId?: string;
  setSelectedChat: (chat: string) => void;
};

export default function ChatListItem (props: ChatListItemProps) {
  const { chat, selectedChatId, setSelectedChat } = props;
  const selected = selectedChatId === chat._id;
  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          onClick={() => {
            setSelectedChat(chat._id);
          }}
          selected={selected}
          color="neutral"
          sx={{
            flexDirection: 'column',
            alignItems: 'initial',
            gap: 1,
            py: 3
          }}
        >
          <Stack direction="row" spacing={3}>
            <AvatarWithStatus src={chat.avatar} />
            <Box sx={{ flex: 1 }}>
              <Typography variant='h5'>{chat.name}</Typography>
              <Typography variant='body2'>{(chat.lastMessage?.text ?? '').substring(0, 20)}</Typography>
            </Box>
            <Box
              sx={{
                lineHeight: 1.5,
                textAlign: 'right',
              }}
            >
              <Typography
                display={{ xs: 'none', md: 'block' }}
                noWrap={true}
                variant='caption'
              >
                {formatDate(chat?.lastMessage?.createdAt ?? 0)}
              </Typography>
            </Box>
          </Stack>
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
}
