import { FRoom } from '../hooks/type';
import { Avatar, Stack, Typography } from '@mui/material';

type MessagesPaneHeaderProps = {
  room?: FRoom;
  roomMemberIds: number[]
};

export default function MessagesPaneHeader (props: MessagesPaneHeaderProps) {
  const { room, roomMemberIds } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.body',
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <Avatar src={room?.avatar} title={room?.name} />
        <div>
          <Typography
            variant='h5'
            noWrap
          >
            {room?.name}
          </Typography>
          <Typography variant='caption'>{roomMemberIds.length} members</Typography>
        </div>
      </Stack>
    </Stack>
  );
}
