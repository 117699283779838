import { Typography } from "@mui/material";
import Avatar from "../../../common/components/Avatar";
import { WidgetDto } from "../../../services/netServices";
import { useEffect, useState } from "react";


interface LeavesModalProps {
  leavesData: WidgetDto[]
}
export default function LeavesBody ({
  leavesData,
}: LeavesModalProps): JSX.Element {
  const [items, setItems] = useState(leavesData);
  useEffect(() => {
    setItems(leavesData);
  }, [leavesData]);
  // Function to rotate the array
  const rotateArray = () => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      const removedItems = updatedItems.splice(0, 2);
      updatedItems.push(...removedItems);
      return updatedItems;
    });
  };

  // Rotate the array every 2 seconds
  useEffect(() => {
    const interval = setInterval(rotateArray, 3000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  return <table className="table hide-scrollbar">
    <thead>
      <tr>
        <th className='colEmployee'>Employee</th>
        <th className='colScheduled'>Scheduled</th>
        <th className='colStatus'>Status</th>
      </tr>
    </thead>
    <tbody className='body2'>
      {items.map((item: WidgetDto, index: number) => (
        <tr key={`${item.EmployeeId}-${index}`} className="item">
          <td className='colEmployee'>
            <div className='employeeInfo'>
              <Avatar image={item.Avatar ?? ''} color={item.Color ?? ''} name={item.EmployeeName ?? ''} />
              <div className='containerName'>
                <Typography fontWeight="500">
                  {item.EmployeeName ?? ''}
                </Typography>
                <Typography fontWeight="500" variant="body2">
                  {item.GroupName ?? ''}
                </Typography>
              </div>
            </div>
          </td>
          <td className='colScheduled'>
            <div className='containerStt'>
              {item.Scheduled ?? '-'}
              {(Number(item.SBreakTime) !== 0) ? <span>{`(${item.SBreakTime})`}</span> : null}
            </div>
          </td>

          <td className='colStatus'>
            <span className='badge' style={{ backgroundColor: item.SShirtType?.Color ?? '' }}>
              {item.SShirtType?.Name ?? ''}
            </span>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
}