
export const defaultLoadItems = 100
export interface FUser {
  _id?: number | null
  name?: string | null
  avatar?: string | null
  color?: string| null
  group_name?: string| null
}
export interface FImage {
  uri: string
  fileName: string
}
export interface FMessage {
  _id: string | number
  text: string
  replyText?: string
  createdAt: number
  user: FUser
  likes?: FUser[]
  image?: string
  video?: string
  audio?: string
  system?: boolean
  sent?: boolean
  received?: boolean
  pending?: boolean
}

export interface FRoom {
  _id: string
  name: string
  avatar?: string
  lastMessage: FMessage
  isPrivateChat?: boolean
}
export interface Settings {
  companyId?: string | null
  currentRoomId?: string
}