import * as React from 'react';

import MessagesPane from './components/MessagesPane';
import ChatsPane from './components/ChatsPane';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useRoomChat } from './hooks/useRoomChat';

export default function Messenger() {
  const [selectedChat, setSelectedChat] = React.useState<string>();
  const settings = useMemo(() => {
    const local= localStorage.getItem('USER')
    const userProfile = JSON.parse(local ?? '{}')
    const u = {
      _id: userProfile?.UserId,
      name: userProfile?.FullName,
      avatar: userProfile?.Avatar,
      color: userProfile?.Color,
      group_name: userProfile?.PrimaryGroup?.GroupName,
    }
    return {
      userProfile: u,
      company: userProfile?.User?.db_name
    }
  }, [])

  const { rooms } = useRoomChat(
    {
      companyId: settings.company,
    },
    settings.userProfile,
  );
  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'minmax(min-content, min(30%, 400px)) 1fr',
        },
      }}
    >
      <Box
        sx={{
          position: { xs: 'fixed', sm: 'sticky' },
          transform: {
            xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
            sm: 'none',
          },
          transition: 'transform 0.4s, width 0.4s',
          zIndex: 100,
          width: '100%',
          top: 52,
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        <ChatsPane
          chats={rooms}
          selectedChatId={selectedChat}
          setSelectedChat={setSelectedChat}
        />
      </Box>
      {selectedChat && <MessagesPane selectedChatId={selectedChat} settings={settings} />}
    </Box>
  );
}
