/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { DepartmentDetail } from '../models';
// @ts-ignore
import type { DepartmentDetailV2 } from '../models';
// @ts-ignore
import type { Departments } from '../models';
// @ts-ignore
import type { FilterTimeSheet } from '../models';
// @ts-ignore
import type { GetAllDepartmentInformation200Response } from '../models';
// @ts-ignore
import type { InternetAccess } from '../models';
// @ts-ignore
import type { Locations } from '../models';
// @ts-ignore
import type { LocationsDetail } from '../models';
/**
 * DepartmentApi - axios parameter creator
 * @export
 */
export const DepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createLocation
         * @param {Locations} body createLocation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (body: Locations, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createLocation', 'body', body)
            const localVarPath = `/api/department/createLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteLocation
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLocation', 'id', id)
            const localVarPath = `/api/department/deleteLocation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDepartment
         * @param {boolean} getDeleted 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartment: async (getDeleted: boolean, minTime: string, maxTime: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getDeleted' is not null or undefined
            assertParamExists('getAllDepartment', 'getDeleted', getDeleted)
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getAllDepartment', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getAllDepartment', 'maxTime', maxTime)
            const localVarPath = `/api/department/getAllDepartment/{getDeleted}/{minTime}/{maxTime}`
                .replace(`{${"getDeleted"}}`, encodeURIComponent(String(getDeleted)))
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDepartmentInformation
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentInformation: async (page: number, limit: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getAllDepartmentInformation', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getAllDepartmentInformation', 'limit', limit)
            const localVarPath = `/api/department/getAllDepartmentInformation/{page}/{limit}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDepartmentByID
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentByID: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartmentByID', 'id', id)
            const localVarPath = `/api/department/getDepartmentByID/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListDepartmentByEmployeeCode
         * @param {string} empCode 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDepartmentByEmployeeCode: async (empCode: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empCode' is not null or undefined
            assertParamExists('getListDepartmentByEmployeeCode', 'empCode', empCode)
            const localVarPath = `/api/department/getListDepartmentByEmployeeCode/{empCode}`
                .replace(`{${"empCode"}}`, encodeURIComponent(String(empCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListDepartments
         * @param {FilterTimeSheet} body getListDepartments Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDepartments: async (body: FilterTimeSheet, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListDepartments', 'body', body)
            const localVarPath = `/api/department/getListDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListLocation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListLocation: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/department/getListLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListSettings
         * @param {string} type 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSettings: async (type: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getListSettings', 'type', type)
            const localVarPath = `/api/department/getListSettings/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDepartment
         * @param {number} id 
         * @param {DepartmentDetail} body updateDepartment Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment: async (id: number, body: DepartmentDetail, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDepartment', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDepartment', 'body', body)
            const localVarPath = `/api/department/updateDepartment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateLocation
         * @param {number} id 
         * @param {Locations} body updateLocation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (id: number, body: Locations, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLocation', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateLocation', 'body', body)
            const localVarPath = `/api/department/updateLocation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentApi - functional programming interface
 * @export
 */
export const DepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createLocation
         * @param {Locations} body createLocation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(body: Locations, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.createLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteLocation
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.deleteLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getAllDepartment
         * @param {boolean} getDeleted 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDepartment(getDeleted: boolean, minTime: string, maxTime: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDepartment(getDeleted, minTime, maxTime, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getAllDepartment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getAllDepartmentInformation
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDepartmentInformation(page: number, limit: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllDepartmentInformation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDepartmentInformation(page, limit, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getAllDepartmentInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getDepartmentByID
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentByID(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Departments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentByID(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getDepartmentByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListDepartmentByEmployeeCode
         * @param {string} empCode 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListDepartmentByEmployeeCode(empCode: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListDepartmentByEmployeeCode(empCode, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getListDepartmentByEmployeeCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListDepartments
         * @param {FilterTimeSheet} body getListDepartments Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListDepartments(body: FilterTimeSheet, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDetailV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListDepartments(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getListDepartments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListLocation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListLocation(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationsDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListLocation(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getListLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListSettings
         * @param {string} type 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListSettings(type: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InternetAccess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListSettings(type, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.getListSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateDepartment
         * @param {number} id 
         * @param {DepartmentDetail} body updateDepartment Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartment(id: number, body: DepartmentDetail, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Departments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartment(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.updateDepartment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateLocation
         * @param {number} id 
         * @param {Locations} body updateLocation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(id: number, body: Locations, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentApi.updateLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepartmentApi - factory interface
 * @export
 */
export const DepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentApiFp(configuration)
    return {
        /**
         * 
         * @summary createLocation
         * @param {Locations} body createLocation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(body: Locations, source?: string, options?: any): AxiosPromise<Locations> {
            return localVarFp.createLocation(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteLocation
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(id: number, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteLocation(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDepartment
         * @param {boolean} getDeleted 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartment(getDeleted: boolean, minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<Array<DepartmentDetail>> {
            return localVarFp.getAllDepartment(getDeleted, minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDepartmentInformation
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentInformation(page: number, limit: number, source?: string, options?: any): AxiosPromise<GetAllDepartmentInformation200Response> {
            return localVarFp.getAllDepartmentInformation(page, limit, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDepartmentByID
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentByID(id: number, source?: string, options?: any): AxiosPromise<Departments> {
            return localVarFp.getDepartmentByID(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListDepartmentByEmployeeCode
         * @param {string} empCode 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDepartmentByEmployeeCode(empCode: string, source?: string, options?: any): AxiosPromise<Array<DepartmentDetail>> {
            return localVarFp.getListDepartmentByEmployeeCode(empCode, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListDepartments
         * @param {FilterTimeSheet} body getListDepartments Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDepartments(body: FilterTimeSheet, source?: string, options?: any): AxiosPromise<Array<DepartmentDetailV2>> {
            return localVarFp.getListDepartments(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListLocation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListLocation(source?: string, options?: any): AxiosPromise<Array<LocationsDetail>> {
            return localVarFp.getListLocation(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListSettings
         * @param {string} type 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSettings(type: string, source?: string, options?: any): AxiosPromise<Array<InternetAccess>> {
            return localVarFp.getListSettings(type, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateDepartment
         * @param {number} id 
         * @param {DepartmentDetail} body updateDepartment Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment(id: number, body: DepartmentDetail, source?: string, options?: any): AxiosPromise<Departments> {
            return localVarFp.updateDepartment(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateLocation
         * @param {number} id 
         * @param {Locations} body updateLocation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(id: number, body: Locations, source?: string, options?: any): AxiosPromise<Locations> {
            return localVarFp.updateLocation(id, body, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentApi - object-oriented interface
 * @export
 * @class DepartmentApi
 * @extends {BaseAPI}
 */
export class DepartmentApi extends BaseAPI {
    /**
     * 
     * @summary createLocation
     * @param {Locations} body createLocation Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public createLocation(body: Locations, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).createLocation(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteLocation
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public deleteLocation(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).deleteLocation(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDepartment
     * @param {boolean} getDeleted 
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getAllDepartment(getDeleted: boolean, minTime: string, maxTime: string, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getAllDepartment(getDeleted, minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDepartmentInformation
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getAllDepartmentInformation(page: number, limit: number, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getAllDepartmentInformation(page, limit, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDepartmentByID
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getDepartmentByID(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getDepartmentByID(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListDepartmentByEmployeeCode
     * @param {string} empCode 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getListDepartmentByEmployeeCode(empCode: string, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getListDepartmentByEmployeeCode(empCode, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListDepartments
     * @param {FilterTimeSheet} body getListDepartments Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getListDepartments(body: FilterTimeSheet, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getListDepartments(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListLocation
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getListLocation(source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getListLocation(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListSettings
     * @param {string} type 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getListSettings(type: string, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getListSettings(type, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateDepartment
     * @param {number} id 
     * @param {DepartmentDetail} body updateDepartment Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public updateDepartment(id: number, body: DepartmentDetail, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).updateDepartment(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateLocation
     * @param {number} id 
     * @param {Locations} body updateLocation Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public updateLocation(id: number, body: Locations, source?: string, options?: RawAxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).updateLocation(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }
}

