import { Box, Typography, CircularProgress, Container } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { WidgetDto, WorkingApi } from '../../services/netServices'
import ModalDepartment from './components/ModalDepartment'
import PresentBody from './components/PresentBody'
import LeaveBody from './components/LeaveBody'
import NoShowBody from './components/NoShowBody'
import { Header } from './components/Header'

const App: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [workingData, setWorkingData] = useState<WidgetDto[]>([])
  const [noShowData, setNoShowData] = useState<WidgetDto[]>([])
  const [leavesData, setLeavesData] = useState<WidgetDto[]>([])
  const [loadingWorking, setLoadingWorking] = useState<boolean>(true)
  const [loadingNoShow, setLoadingNoShow] = useState<boolean>(true)
  const [loadingLeaves, setLoadingLeaves] = useState<boolean>(true)
  const [departmentId, setDepartmentId] = useState<string | null | undefined>(localStorage.getItem('DEPARTMENTID'))
  const dataLocalStorage = localStorage.getItem('USER')
  const parsedData = JSON.parse(dataLocalStorage ?? '')
  const token = parsedData?.User?.token


  async function fetchAndSetData (d_id: number) {
    try {
      new WorkingApi().workingPresentsGet(Number(d_id ?? 0), token).then((presents) => {
        setWorkingData(presents.data)
        setLoadingWorking(false)
      })
      new WorkingApi().workingNoShowsGet(Number(d_id ?? 0), token).then((noShows) => {
        setNoShowData(noShows.data)
        setLoadingNoShow(false)
      })
      new WorkingApi().workingLeavesGet(Number(d_id ?? 0), token).then((leaves) => {
        setLeavesData(leaves.data)
        setLoadingLeaves(false)
      })
    } catch (error) {
      console.log(error)
    } finally {

      setLoadingWorking(false)
      setLoadingNoShow(false)
      setLoadingLeaves(false)
    }
  }

  useEffect(() => {
    fetchAndSetData(Number(departmentId ?? 0))
    const myInterval = setInterval(() => {
      fetchAndSetData(Number(departmentId ?? 0))
    }, 3 * 60000)
    return () => clearInterval(myInterval)
  }, [])

  const handleDialogClose = () => setDialogOpen(false)
  const handleDialogOpen = () => setDialogOpen(true)

  const handleDepartmentSelect = async (d_id: number) => {
    setDepartmentId(d_id.toString())
    localStorage.setItem('DEPARTMENTID', d_id.toString())
    fetchAndSetData(d_id)
  }

  return (
    <>
      <main className="main">
        <Header onPress={handleDialogOpen} />
        <div className="app">
          <Container maxWidth={false} className="h-100">
            <div className="row h-100">
              <section className="col left h-100">
                <Box className='container-box h-100'>
                  <Typography variant='h4' mb={2}>
                    {`Present (${workingData.length})`}
                  </Typography>
                  <div className="table-container">
                    {loadingWorking && <div className="loading"> <CircularProgress size={24} /> </div>}
                    {!loadingWorking && <>
                      {workingData.length === 0 && <Typography textAlign='center' className='no-data-container'>
                        No one is working today!
                      </Typography>}
                      {workingData.length > 0 && <PresentBody workingData={workingData} />}
                    </>}
                  </div>
                </Box>
              </section>
              <section className="col right h-100">
                <Box className='container-box'>
                  <Typography variant='h4' mb={2}>
                    {`No show (${noShowData.length})`}
                  </Typography>
                  <div className="table-container">
                    {loadingNoShow && <div className="loading"> <CircularProgress size={24} /> </div>}
                    {!loadingNoShow && <>
                      {noShowData.length === 0 && <Typography textAlign='center' className='no-data-container'>
                        No one has missed clocking in today!
                      </Typography>}
                      {noShowData.length > 0 && <NoShowBody noShowData={noShowData} />}
                    </>}
                  </div>
                </Box>
                <Box className='container-box'>
                  <Typography variant='h4' mb={2}>
                    {`Leave (${leavesData.length})`}
                  </Typography>
                  <div className="table-container">
                    {loadingLeaves && <div className="loading"> <CircularProgress size={24} /> </div>}
                    {!loadingLeaves && <>
                      {leavesData.length === 0 && <Typography textAlign='center' className='no-data-container'>
                        No employees are on leave today!
                      </Typography>}
                      {leavesData.length > 0 && <LeaveBody leavesData={leavesData} />}
                    </>}
                  </div>
                </Box>
              </section>
            </div>
          </Container>
        </div>
      </main>
      <ModalDepartment
        open={dialogOpen}
        onClose={handleDialogClose}
        handleDepartmentSelect={handleDepartmentSelect}
      />
    </>
  )
}

export default App
