import * as React from 'react';
import AvatarWithStatus from './AvatarWithStatus';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import MessagesPaneHeader from './MessagesPaneHeader';
import { Box, Stack } from '@mui/material';
import { useChat } from '../hooks/useChat';

type MessagesPaneProps = {
  settings: any
  selectedChatId?: string
};

export default function MessagesPane({settings, selectedChatId}: MessagesPaneProps) {
  const { messages, room, roomMemberIds, sendMessage } =
    useChat(
      {
        companyId: settings.company,
        currentRoomId: selectedChatId,
      },
      settings.userProfile,
    )


  return (
    <Box
      sx={{
        height: { xs: 'calc(100dvh - var(--Header-height))', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
      }}
    >
      <MessagesPaneHeader room={room} roomMemberIds={roomMemberIds} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          p: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {messages?.map((message, index: number) => {
            const isYou = message.user?._id === settings.userProfile._id;
            return (
              <Stack
                key={index}
                direction="row"
                spacing={3}
                flexDirection={isYou ? 'row-reverse' : 'row'}
              >
                {!isYou && (
                  <AvatarWithStatus
                    src={message.user?.avatar ?? ''}
                    title={message.user?.name ?? ''}
                  />
                )}
                <ChatBubble variant={isYou ? 'sent' : 'received'} message={message} />
              </Stack>
            );
          })}
        </Stack>
      </Box>
      <MessageInput
        onSubmit={(message: string) => {
          sendMessage(message)
        }}
      />
    </Box>
  );
}
