import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ChatListItem from './ChatListItem';
import { ChatProps } from '../types';
import { Box, List, Stack, TextField, Typography } from '@mui/material';
import { FRoom } from '../hooks/type';

type ChatsPaneProps = {
  chats: FRoom[];
  setSelectedChat: (chat: string) => void;
  selectedChatId?: string;
};

export default function ChatsPane (props: ChatsPaneProps) {
  const { chats, setSelectedChat, selectedChatId } = props;
  return (
    <Box
      sx={{
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <Typography
          variant='h4'
          sx={{ mr: 'auto' }}
        >
          Messages
        </Typography>
      </Stack>
      <Box sx={{ p: 3, pt: 0 }}>
        <TextField
          variant="outlined"
          InputProps={{
            endAdornment: <SearchRoundedIcon />
          }}
          fullWidth
          placeholder="Search"
          aria-label="Search"
        />
      </Box>
      <List
        sx={{
          py: 0,
          '--ListItem-paddingY': '0.75rem',
          '--ListItem-paddingX': '1rem',
        }}
      >
        {chats?.map((chat) => (
          <ChatListItem
            key={chat._id}
            chat={chat}
            setSelectedChat={setSelectedChat}
            selectedChatId={selectedChatId}
          />
        ))}
      </List>
    </Box>
  );
}
