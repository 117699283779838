import { CommonApi } from "./netServices";

export function processAPIURL(
  urlAPI: string | undefined | null,
  urlReport: string | undefined | null
) {
  if (urlAPI) localStorage.setItem("PHPAPI", urlAPI);
  if (urlReport) localStorage.setItem("NETAPI", urlReport);
}

export function saveToken(token: string | undefined) {
  if (token) localStorage.setItem("TOKEN", token);
}

export function initAPIURL(env: "BETA" | "VN_API" | "VN_FULL" | "LIVE") {
  switch (env) {
    case "BETA":
      return {
        API_PHP: "https://apibeta.titanplan.com/",
        API_NET: "https://dotnet.apibeta.titanplan.com",
      };
    case "VN_API":
      return {
        API_PHP: "https://vn.api.titanplan.com/",
        API_NET: "https://core.api.titanplan.com",
      };
    case "VN_FULL":
      return {
        API_PHP: "https://vn.api.titanplan.com/",
        API_NET: "https://vn.core.api.titanplan.com",
      };
    default:
      return {
        API_PHP: "https://api.titanplan.com/",
        API_NET: "https://core.api.titanplan.com",
      };
  }
}

export async function initUser() {
  try{
    const { data } = await new CommonApi().commonGetUserProfileGet();
    localStorage.setItem("USER", JSON.stringify(data));
    processAPIURL(data.UrlApi, data.UrlReport);

  }catch{
    alert('Error! Please try again or contact your admin!')
  }
}
