/* tslint:disable */
/* eslint-disable */
/**
 * TitanPlantNetApi
 * TitanPlantNetApi
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CompanyInfo } from '../models';
// @ts-ignore
import type { DepartmentInfo } from '../models';
// @ts-ignore
import type { ModuleDto } from '../models';
// @ts-ignore
import type { UserProfileDto } from '../models';
/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAllDepartmentGet: async (token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Common/GetAllDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCompanyInformationGet: async (token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Common/GetCompanyInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetListModuleGet: async (token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Common/GetListModule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetUserProfileGet: async (token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Common/GetUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAllDepartmentGet(token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAllDepartmentGet(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAllDepartmentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCompanyInformationGet(token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCompanyInformationGet(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCompanyInformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetListModuleGet(token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetListModuleGet(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetListModuleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetUserProfileGet(token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetUserProfileGet(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetUserProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAllDepartmentGet(token?: string, options?: any): AxiosPromise<Array<DepartmentInfo>> {
            return localVarFp.commonGetAllDepartmentGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCompanyInformationGet(token?: string, options?: any): AxiosPromise<CompanyInfo> {
            return localVarFp.commonGetCompanyInformationGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetListModuleGet(token?: string, options?: any): AxiosPromise<Array<ModuleDto>> {
            return localVarFp.commonGetListModuleGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetUserProfileGet(token?: string, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.commonGetUserProfileGet(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAllDepartmentGet(token?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAllDepartmentGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCompanyInformationGet(token?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCompanyInformationGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetListModuleGet(token?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetListModuleGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetUserProfileGet(token?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetUserProfileGet(token, options).then((request) => request(this.axios, this.basePath));
    }
}

