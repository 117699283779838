import { createTheme } from "@mui/material/styles";
// A custom theme for this app
const defaultControlHeight = 40;
const defaultRowHeight = 40;

export const Colors = {
  primary: "#08b0a0",
  secondary: "#2e383d",
  danger: "#e84d48",
  warning: "#f5a623",
  info: "#1aa3ff",
  success: "#417505",
  black: "#000000",
  white: "#FFFFFF",
  gray100: "#f1f1f1",
  backdropBg: "rgba(33,33,33,.48)",
};

const theme = createTheme({
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => "none",
  },
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.danger,
      contrastText: Colors.white,
    },
    warning: {
      main: Colors.warning,
      contrastText: Colors.white,
    },
    info: {
      main: Colors.info,
      contrastText: Colors.white,
    },
    success: {
      main: Colors.success,
      contrastText: Colors.white,
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
      secondary: "#7b7c7e",
    },
    divider: "#e5e5e5",
    background: {
      default: Colors.white,
    },
    grey: {
      100: Colors.gray100,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontSize: 15,
    fontFamily:
      '"Segoe UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 14,
      color: "#7b7c7e",
    },
    button: {
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 13,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 13,
    },
    caption: {
      fontSize: 12,
      color: "#7b7c7e",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          "&.MuiOutlinedInput-input:not(.MuiInputBase-multiline)": {
            height: defaultControlHeight,
          },
          "&.MuiInputBase-multiline": {
            px: 2,
            py: 3
          },
          "& input::placeholder": {
            color: "text.secondary",
            opacity: 1,
          },
          "&:not(.Mui-disabled):before": {
            borderBottomWidth: "1px",
            borderBottomColor: "divider",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomWidth: "1px",
            borderBottomColor: "divider",
          },
          "&:not(.Mui-disabled):after": {
            borderBottomWidth: "1px",
          },
          "&.noBorder:before": {
            borderBottomWidth: "0px",
          },
          "&.noBorder:hover:not(.Mui-disabled):before": {
            borderBottomWidth: "0px",
          },
          "&.noBorder:not(.Mui-disabled):after": {
            borderBottomWidth: "0px",
          },
          "&.MuiInput-root": {
            minWidth: "auto",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          "& .MuiOutlinedInput-input": {
            padding: "0 8px",
            minHeight: defaultControlHeight,
            display: "flex",
            alignItems: "center",
          },
          "&.MuiAutocomplete-input": {
            padding: "0",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "divider",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "divider",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "1px !important",
              borderColor: "primary.main",
            },
          "& .MuiIconButton-root": {
            marginRight: "-7px",
          },
          "&.noBorder:before": {
            borderBottomWidth: "0px",
          },
          "&.noBorder:hover:not(.Mui-disabled):before": {
            borderBottomWidth: "0px",
          },
          "&.noBorder:not(.Mui-disabled):after": {
            borderBottomWidth: "0px",
          },
          "&.MuiInput-root": {
            minWidth: "auto",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          "&.MuiButton-sizeMedium": {
            height: defaultControlHeight,
          },
          "& .MuiButton-startIcon": {
            marginLeft: 0,
          },
        },
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        sx: { textDecoration: "none", color: "#2f6092" },
      },
    },
    MuiListSubheader: {
      defaultProps: {
        sx: {
          lineHeight: `${defaultControlHeight}px`,
          color: "text.secondary",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          minWidth: 200,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "divider",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "divider",
          },
          "& .MuiInput-input": {
            paddingTop: "5px",
          },
          "& .MuiTypography-root": {
            lineHeight: "inherit",
          },
          "& .MuiInput-input:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        sx: {
          "& .MuiAutocomplete-endAdornment": {
            paddingRight: "7px",
          },
          "& .MuiInput-root .MuiInput-input": {
            paddingLeft: "7px",
          },
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        sx: {
          color: "text.primary",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          "&.mw-200": {
            minWidth: 180,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: {
          "&.MuiFormControl-fullWidth": {
            minWidth: 150,
          },
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          color: "text.primary",
          borderColor: "divider",
          py: 0,
          px: 3,
          height: defaultRowHeight,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          py: "7px",
          px: 2,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          py: "7px",
          px: 2,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        sx: {
          minHeight: defaultRowHeight,
          "& .MuiTabs-indicator": {
            height: "1px",
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        sx: {
          minHeight: defaultRowHeight,
          textTransform: "none",
          py: 1,
        },
      },
    },
    MuiModal: {
      defaultProps: {
        sx: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& >.MuiBox-root": {
            position: "relative",
            top: "auto",
            left: "auto",
            transform: "none",
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          p: 4,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 4,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          p: 4,
          pt: 0,
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        sx: {
          fontSize: 12,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        sx: {
          "&.MessengerBox-text": {
            fontSize: 16,
            fontWeight: 400,
            whiteSpace: "pre-wrap",
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        sx: {
          "& .MuiPaper-root": {
            minWidth: 320,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: Colors.backdropBg,
          },
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        sx: {
          p: 0,
        },
      },
    },
  },
});

export default theme;
